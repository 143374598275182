import React, {useEffect} from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Secteur from "../components/Secteur"
import illus from "../images/mockup-cartoA4.jpg"
import iconDL from "../images/download.svg"
import {
  allCategoriesType,
  allCartoFilesType,
  CartoFiles,
} from "../utils/model"
import {useCategoryTree} from "../utils/custom-hooks";

type SecteursPageProps = {
  data: { files: allCartoFilesType }
}

function SecteursPage(props: SecteursPageProps) {
  useEffect(() => {
    document.body.className = "page-secteurs"
}, []);

  const categories = useCategoryTree();

  const files = props.data?.files.nodes
    ? props.data?.files.nodes.map(l => {
        let fileURL = null
        if (l.data.PDF && l.data.PDF.localFiles.length > 0) {
          fileURL = l.data.PDF.localFiles[0].publicURL
        }
        return new CartoFiles(l.id, l.data.Name, l.data.Notes, fileURL)
      })
    : []
  return (
    <Layout contentClassName="container">
     <SEO 
      title="Les secteurs des entreprises de la transition écologique, énergétique & climat"
      description="Découvrez tous les secteurs d'activité contribuant de façon directe ou indirecte à la transition carbone, écologique et durable, et la lutte contre le réchauffement climatique"
      gtmPageType="les-secteurs" 
      gtmPageName="les-secteurs" />

      <div className="overflow-x-hidden">
        <div className="px-2 container md:mx-auto md:max-w-2xl lg:max-w-3xl pt-16">
          <h1 className="text-3xl xl:text-5xl leading-none text-primary font-black mb-3">
            Les secteurs
          </h1>
        </div>

        <div className="mx-2 pt-6 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl pb-16 ">
          <div className="bg-primary text-white relative px-0 py-10  mt-2 mb-8 ml-16 max-w-md hidden lg:block float-right">
            <img
              className=""
              style={{
                width: "448px",
                height: "243px",
              }}
              src={illus}
            />
            <div
              className="absolute h-full bg-primary left-auto  z-0 top-0 right-0 transform translate-x-full"
              style={{ width: "calc(100%)" }}
            ></div>
          </div>

          <div className="md:px-2 container md:mx-auto md:max-w-2xl lg:max-w-3xl">
            <div className="bg-primary text-white relative p-10 mb-8 lg:hidden text-center items-center">
              <img
                className="mx-auto"
                style={{
                  width: "320px",
                  height: "auto",
                }}
                src={illus}
              />
            </div>
            <p className="markdown">
              Nous avons classé et organisé les organisations retenues en 
              12 secteurs et jusqu'à plus de 300 sous-secteurs. Découvrez la vue
              synthétique de la cartographie des secteurs sur ses deux premiers
              niveaux.
            </p>
            <br /> A télécharger ici :<br />
            {files.map(l => (
              <a
                key={l.id}
                href={l.PDFURL}
                target="_blank"
                className="flex items-center mb-5 mt-5 no-underline"
              >
                <img
                  src={iconDL}
                  alt=""
                  width="24"
                  height="24"
                  className="mr-2"
                />
               
                <div className="custom-underline" dangerouslySetInnerHTML={{ __html: l.notes?.childMarkdownRemark.html }}></div>
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="mx-2 pt-5 md:mx-auto md:max-w-2xl lg:max-w-3xl pb-16">
        <h1 className="text-3xl xl:text-5xl text-primary font-black capitalize mb-3"></h1>
        <p className="text-gray-700 mb-8">
          Pour aller plus loin, plongez au cœur de l’arborescence pour découvrir
          l’ensemble des secteurs et sous-secteurs d’activité contribuant à la
          transition carbone :
        </p>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {categories.root.children.map(cat => (
            <Secteur key={cat.id} category={cat} />
          ))}
          {categories.root.children.length % 2 === 1 && (
            <div
              className="px-4 py-6 mt-4"
              style={{ width: "150px", height: "175px" }}
            >
              &nbsp;
            </div>
          )}
        </div>
      </div>

      <div className="bg-primary text-white relative p-10 py-16 text-center">
        <div className="max-w-4xl mx-auto">
          <div className="font-sans font-bold font-4xl text-white text-center mb-10">
            Pour en savoir plus sur le fonctionnement de la cartographie,
            découvrez le mode d’emploi de shiftyourjob.org
          </div>
          <Link
            to={"/guide-utilisation"}
            className="font-sans font-bold text-sm bg-secondary text-primary text-center rounded px-6 py-4 transition duration-300 ease transition-colors hover:bg-white"
          >
            Découvrir le mode d'emploi
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SecteursQuery {
    files: allAirtable(filter: { table: { eq: "CartoFiles" } }) {
      nodes {
        id
        data {
          Name
          Notes{
            childMarkdownRemark {
              html
            }
          }
          PDF {
            localFiles {
              publicURL
            }
          }
        }
      }
    }
  }
`
export default SecteursPage
