import React from "react"
import { Link } from "gatsby"
import {Category} from "../utils/model"

const defaultProps = {
    linkText: `View the Secteur`,
}

type SecteurProps = { category: Category } & typeof defaultProps

const Secteur = ({ category }: SecteurProps) => {

  return (
    <Link to={category.slug}>
      <div className="font-sans relative px-4 py-6 bg-white rounded-md shadow-xl transition duration-500 ease transition-shadow hover:shadow-2xl" style={{height:"175px"}}>
        <div className="text-sm font-bold text-gray-500">{category.totalOrgCount}</div>
        <div className="text-sm font-bold text-primary capitalize-first-letter">{category.name}</div>
        <img className="absolute" style={{bottom:"10px", left:"10px", maxWidth:"60px", maxHeight:'60px'}} src={category.pictoURL} />
      </div>
    </Link>
  )
}
export default Secteur
    
Secteur.defaultProps = defaultProps
